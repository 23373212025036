export const hero = {
  title: `Make your back-of-house run like clockwork and increase throughput with our Kitchen Display System`,
  subTitle: "Kitchen displays",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/kitchen-displays/demo",
  },
};

export const heroImage = {
  imageName: "products-kitchen-hero.png",
};

export const secondaryHeroTitle = {
  title: "Speed up operations with real-time order tracking",
  subtext:
    "Orders sent to the kitchen real-time based on customizable fire options ensures your kitchen is running quickly and efficiently. Plus, tracking how long each order is in the queue helps you make data-driven decisions to make line and staff adjustments.",
  imageName: "products-kitchen-secondary-hero.png",
  mobileImageName: "products-kitchen-secondary-hero-mobile.png",
};

export const productOverview = {
  subSections: [
    {
      title: "Increase throughput with routing",
      subtext:
        "Boost the number of orders your kitchen can fulfill by improving your kitchen’s workflow with routing. Efficiently route entire orders or individual items to specific stations. Looking for more advanced routing and configurations? Ask us about our partnership with QSR Automations.",
      imageName: "products-kitchen-product-1.png",
      cta: {
        title: "Learn more",
        target: "/enterprise/kitchen-displays/demo",
      },
    },
    {
      title: "Improve order accuracy and reduce training time",
      subtext:
        "Easy to train and onboard new staff, with photo based menu items that highlight ingredients, prepping instructions and more.",
      imageName: "products-kitchen-product-2.png",
      cta: {
        title: "Learn more",
        target: "/enterprise/kitchen-displays/demo",
      },
    },
    {
      title: "Quickly fulfill orders on-the-go",
      subtext:
        "Provide staff the ability to fulfill and close out orders on the go—whether making in-seat deliveries at a basketball game or fulfilling curb-side pick-up orders.",
      imageName: "products-kitchen-product-3.png",
      cta: {
        title: "Learn more",
        target: "/enterprise/kitchen-displays/demo",
      },
    },
  ],
};

export const productsWithLinks = {
  title: "Built on a true omnichannel ecosystem",
  products: [
    {
      title: "Point-of-Sale",
      description:
        "Reduce wait times with a user-friendly POS solution that processes orders and payments fast, even when offline.",
      linkTo: "/enterprise/pos-terminals",
      imageName: "products-kiosk.png",
    },
    {
      title: "Handhelds",
      description:
        "Take orders and process payments courtside, tableside, or while guests are still in line using on-the-go point of sale solutions.",
      linkTo: "/enterprise/handhelds",
      imageName: "products-handheld.png",
    },
    {
      title: "Kiosks",
      description:
        "Give your guests direct control over their ordering experience as you speed up order fulfillment and improve your bottom line.",
      linkTo: "/enterprise/kiosks",
      imageName: "products-kiosk-2.png",
    },
  ],
};

export const footer = {
  title: "Book a consultation",
  subtext:
    "See for yourself how our enterprise cloud POS and management platform can transform the guest experience, provide valuable business insights, and deliver ROI to your enterprise.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/kitchen-displays/demo",
  },
};
